.e-grid .e-row.below-30 .e-rowcell { 
    color: green; 
    font-weight: 500; 
    background-color: rgba(24, 215, 43, 0.279);
  } 

.e-grid .e-row.below-40 .e-rowcell { 
    color: rgb(130, 133, 4); 
    font-weight: 500; 
    background-color: rgba(248, 245, 61, 0.479);
  } 