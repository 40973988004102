.posisi{
    margin: 30px 30px !important;
}

.posisi-poli{
    margin-left: 25px;
    margin-bottom: 0px !important;
}

.posisi-waktu{
    padding-top: -20px;
}

.posisi-nomor{
    margin-top: 10px;
    margin-left: 33px;
    /* margin-bottom: 0px !important; */
}

.posisi-klinik{
    /* margin-top: 10px; */
    margin-left: 5px;
    /* margin-bottom: 0px !important; */
}

/* @media print {
    @page { size: landscape; }
  } */