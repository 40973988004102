.site-layout-content {
  /* min-height: 280px; */
  min-height: 89vh;
  padding: 24px;
  background: rgb(242, 241, 241);
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px -30px;
  /* background: rgba(255, 255, 255, 0.3); */
}

.avatar {
  float: right;
  margin: 16px -30px 0px 0px;
  background-color: white;
  /* color:'#82D7D2' */
  color : #16b6a1;
}

.username {
  float: right;
  margin-right: 10px;
  /* color: white; */
}

.site-layout-background {
  background: #fff;
}

.trigger-dua{
  color:#fff;
  /* background-color: #ec5f5f; */
}